import { Button, DialogActions, DialogContent, Stack } from '@mui/material';
import { ApprovedMedications } from '../cards/approved-medications';
import { Allergies } from '../cards/allergies';
import { Ailments } from '../cards/ailments';
import { ReactNode, useEffect } from 'react';
import { useMedicalInfo } from '../useMedicalInformation';
import { CloseInfo } from '../close';

interface ViewDetailsProps {
  children: ReactNode;
  enableForcedMedicalDetailsUpdates?: boolean;
  handleContinue?: () => void;
  studentIndex?: number;
  studentCount?: number;
}

const InfoView = ({
  children,
  enableForcedMedicalDetailsUpdates = false,
  handleContinue,
  studentIndex,
  studentCount,
}: ViewDetailsProps) => {
  const {
    data,
    handleView,
    handleClose,
    handleSave,
    setinfoToInfoView,
    isUpdating,
    isUpdateSuccess,
    isInfoToInfoView,
  } = useMedicalInfo();

  useEffect(() => {
    if (isUpdateSuccess) {
      if (studentIndex !== undefined && studentCount !== undefined) {
        if (studentIndex === studentCount && !isInfoToInfoView) {
          handleView('success');
        } else {
          if (handleContinue && studentIndex < studentCount) {
            handleView('view');
            setinfoToInfoView(true);
            handleContinue();
          }
        }
      } else {
        handleView('success');
      }
    }
  }, [isUpdateSuccess]);

  return (
    <>
      <DialogContent>
        {!enableForcedMedicalDetailsUpdates && (
          <CloseInfo close={handleClose} />
        )}
        {children}
        <Stack py={3} gap={3}>
          <ApprovedMedications items={data?.medications ?? []} />
          <Allergies items={data?.allergies ?? []} />
          <Ailments items={data?.ailments ?? []} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'flex-end' }}>
        <Button
          onClick={() => handleView('edit')}
          variant='contained'
          color={enableForcedMedicalDetailsUpdates ? 'inherit' : 'primary'}
        >
          Edit
        </Button>
        {enableForcedMedicalDetailsUpdates && (
          <Button
            onClick={() => {
              handleSave(true);
              if (studentIndex !== undefined && studentCount !== undefined) {
                if (studentIndex === studentCount) {
                  setinfoToInfoView(false);
                }
              }
            }}
            variant='contained'
            disabled={isUpdating}
          >
            {isUpdating ? 'Saving...' : 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export { InfoView };
