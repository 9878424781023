import { Divider, FormControlLabel, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  FormCheckbox,
  FormDropdown,
  FormRadio,
  FormTextField,
} from '../../../components/form';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { PupilType } from '../../../types/students.type';
import { EventUmbracoItemType } from '../../../types/events.type';
import { useState } from 'react';
import { Button } from '../../../components/button';
import { useCreateRsvpMutation } from '../../../services/events.service';
import { useProfile } from '../../profile/hooks/useProfile';
import { useStudents } from '../../../hooks/useStudents';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  event: EventUmbracoItemType;
}

const RsvpForm = ({ event }: Props) => {
  const token = useMsalIdToken();
  const { profile } = useProfile();
  const queryClient = useQueryClient();

  const { data: pupils } = useStudents();

  const [createRsvp, { isLoading, isSuccess, isError }] =
    useCreateRsvpMutation();
  const [formData, setFormData] = useState<{
    additionalRequests: string | null;
    earlyDismissal: string | null;
    earlyDismissalSign: string | null;
    signer: string | null;
    numberOfAditionalRSVP: string | null;
    accessibleSeatingRequired: string | null;
    department: string | null;
    dietaryRequirements: string | null;
    students: Array<string>;
  }>({
    additionalRequests: null,
    earlyDismissal: 'NO',
    earlyDismissalSign: null,
    signer: null,
    numberOfAditionalRSVP: '0',
    accessibleSeatingRequired: '0',
    department: null,
    dietaryRequirements: null,
    students: [],
  });

  const isValid = () => {
    if (formData.earlyDismissal === 'YES') {
      if (formData.signer !== null && formData.signer !== '') {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const handleChange = (key: string, value: string | Array<string>) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFormSubmit = (formEvent: React.SyntheticEvent) => {
    formEvent.preventDefault();

    const payload = {
      eventId: event.id,
      parent: profile,
      formData,
    };

    void createRsvp({
      token,
      body: payload,
    });

    void queryClient.invalidateQueries({
      queryKey: ['events'],
    });
  };

  const UserDetails = () => {
    return (
      <Stack>
        <Typography fontWeight={600}>Your details</Typography>
        <Stack py={1}>
          <Stack flexDirection='row' gap={1}>
            <Typography fontWeight={300}>Full Name:</Typography>
            <Typography>{`${profile?.forename} ${profile?.surname}`}</Typography>
          </Stack>
          <Stack flexDirection='row' gap={1}>
            <Typography fontWeight={300}>Phone Number:</Typography>
            <Typography>
              {profile?.telephoneNumbers[0]?.phoneNumber ?? 'N/A'}
            </Typography>
          </Stack>
          <Stack flexDirection='row' gap={1}>
            <Typography fontWeight={300}>Mobile:</Typography>
            <Typography>{`N/A`}</Typography>
          </Stack>
          <Stack flexDirection='row' gap={1}>
            <Typography fontWeight={300}>Email:</Typography>
            <Typography>
              {profile?.emailAddresses[0]?.email ?? `N/A`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  const StudentRsvp = () => {
    return (
      <Stack gap={1}>
        <Typography fontWeight={600}>Student RSVP</Typography>

        {pupils?.map((pupil: PupilType) => {
          const pupilFullName = `${pupil?.forename} ${pupil?.surname}`;
          const isSelected = formData?.students?.includes(pupil?.pupilId);
          return (
            <Stack key={pupil?.pupilId}>
              <Typography>{pupilFullName}</Typography>
              <Typography>{pupil?.yearGroup}</Typography>
              <FormControlLabel
                control={<FormCheckbox />}
                label={`I confirm that ${pupilFullName} will attend`}
                checked={isSelected}
                onClick={() => {
                  if (isSelected) {
                    const filterStudents = formData?.students?.filter(
                      (item) => item !== pupil?.pupilId,
                    );
                    setFormData((prevState) => ({
                      ...prevState,
                      students: filterStudents,
                    }));
                  } else {
                    setFormData((prevState) => ({
                      ...prevState,
                      students: [...prevState.students, pupil?.pupilId || ''],
                    }));
                  }
                }}
              />
              <Divider />
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const additionalInfo = () => {
    return (
      <Stack gap={1}>
        <Typography fontWeight={600}>Additional Information</Typography>
        <Stack gap={2}>
          {event?.numberOfAditionalRSVP && (
            <FormDropdown
              label='Number of additional RSVPs (* required)'
              options={[
                { label: '0', value: '0' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
              ]}
              value={formData?.numberOfAditionalRSVP}
              size='small'
              onChange={(numberOfAditionalRSVP) =>
                handleChange(
                  'numberOfAditionalRSVP',
                  numberOfAditionalRSVP as string,
                )
              }
            />
          )}
          {event?.accessibleSeatingRequired && (
            <FormDropdown
              label='Number of accessible seats required (* required)'
              options={[
                { label: '0', value: '0' },
                { label: '1', value: '1' },
                { label: '2', value: '2' },
                { label: '3', value: '3' },
                { label: '4', value: '4' },
                { label: '5', value: '5' },
                { label: '6', value: '6' },
                { label: '7', value: '7' },
              ]}
              value={formData?.accessibleSeatingRequired}
              size='small'
              onChange={(accessibleSeatingRequired) =>
                handleChange(
                  'accessibleSeatingRequired',
                  accessibleSeatingRequired as string,
                )
              }
            />
          )}
          {event?.department && (
            <FormTextField
              size='small'
              label='Department'
              value={formData?.department}
              onChange={(department) =>
                handleChange('department', department?.target?.value)
              }
            />
          )}
          {event?.dietaryRequirements && (
            <FormTextField
              size='small'
              label='Dietary Requirements'
              value={formData?.dietaryRequirements}
              onChange={(dietaryRequirements) =>
                handleChange(
                  'dietaryRequirements',
                  dietaryRequirements?.target?.value,
                )
              }
            />
          )}
          {event?.additionalRequests && (
            <FormTextField
              size='small'
              label='Additional Requests or notes'
              multiline
              rows={4}
              maxRows={20}
              onChange={(additionalRequests) =>
                handleChange(
                  'additionalRequests',
                  additionalRequests?.target?.value,
                )
              }
            />
          )}
        </Stack>
      </Stack>
    );
  };

  const earlyDismissal = () => {
    return (
      <Stack gap={1}>
        <Typography fontWeight={600}>Early Dismissal</Typography>
        <Stack>
          <FormRadio
            onChange={(dismissalEvent) =>
              handleChange('earlyDismissal', dismissalEvent?.target?.value)
            }
            value={formData?.earlyDismissal}
            options={[
              {
                label:
                  'My Student(s) will NOT be leaving early and will stay until normal end of day dismissal',
                value: 'NO',
              },
              {
                label:
                  'YES, I give permission for my student(s) to leave early',
                value: 'YES',
              },
            ]}
          />
        </Stack>
        {formData?.earlyDismissal === 'YES' ? (
          <Stack gap={1} pt={1}>
            <Typography fontSize={{ xs: 11, sm: 14 }}>
              Name of adult who will sign my student(s) out
            </Typography>
            <FormTextField
              value={formData?.signer}
              size='small'
              onChange={(signer) =>
                handleChange('signer', signer?.target?.value)
              }
            />
          </Stack>
        ) : null}
      </Stack>
    );
  };

  if (isSuccess) {
    return (
      <Stack alignItems='center' justifyContent='center' py={4}>
        <CheckCircleIcon sx={{ fontSize: '40px', color: 'green' }} />
        <Typography fontSize={16} textAlign='center'>
          Thank you for your RSVP, <br /> you will receive a confirmation email
          shortly.
        </Typography>
      </Stack>
    );
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Stack gap={2} py={3}>
        <UserDetails />
        <Divider />
        {event?.studentRSVP ? <StudentRsvp /> : null}
        {additionalInfo()}
        <Divider />
        {event?.earlyDismissalPermission ? (
          <>
            {earlyDismissal()}
            <Divider />
          </>
        ) : null}

        {isError && (
          <Stack bgcolor='#FF0000' px={3} py={2}>
            <Typography color='white' fontWeight={600}>
              Failed to rsvp. Please try again.
            </Typography>
          </Stack>
        )}

        <Button
          variant='contained'
          title={isLoading ? 'Submitting...' : 'RSVP'}
          disabled={isLoading || !isValid()}
          type='submit'
        />
      </Stack>
    </form>
  );
};

export { RsvpForm };
