import {
  Card,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useMsalIdToken } from '../../../../hooks/useMsalIdToken';
import { FormLabelView } from '../../components/form-label-view';
import { educations, languages, occupations, qualifications } from '../config';
import { useRef } from 'react';
import { FormTextField } from '../../../../components/form';
import { ParentMyceetyaDetailType } from '../../../../types/myceetya.type';

interface Props {
  currentDetail: ParentMyceetyaDetailType;
  myceetyaParentDetails: ParentMyceetyaDetailType;
  setParentValues: (value: ParentMyceetyaDetailType) => void;
}

const ParentForm = ({
  currentDetail,
  myceetyaParentDetails,
  setParentValues,
}: Props) => {
  const token = useMsalIdToken();
  const details = myceetyaParentDetails;

  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Stack pb={2}>
      <Card>
        <Stack p={2}>
          <Typography fontSize={18} fontWeight={700}>
            {`${details?.firstname} ${details?.surname}`}
          </Typography>
          <Stack py={2} gap={2}>
            <Stack gap={1}>
              <FormLabelView
                title='Do you speak a language other than English at home?'
                subtitle='If more than one language is spoken at home, please indicate the one that is spoken most often.'
              />
              <Select
                value={myceetyaParentDetails?.otherLanguageAtHome as string}
                onChange={(event: SelectChangeEvent) => {
                  if (event.target.value === 'Other') {
                    setParentValues({
                      ...myceetyaParentDetails,
                      otherLanguageAtHome: 'Other',
                    });
                    return;
                  }
                  setParentValues({
                    ...myceetyaParentDetails,
                    otherLanguageAtHome: event.target.value,
                    otherLanguage: null,
                  });
                }}
              >
                {currentDetail?.otherLanguageAtHome && (
                  <MenuItem value={currentDetail?.otherLanguageAtHome}>
                    {currentDetail?.otherLanguageAtHome}
                  </MenuItem>
                )}
                {languages?.map((language: string) => {
                  if (language !== currentDetail?.otherLanguageAtHome) {
                    return <MenuItem value={language}>{language}</MenuItem>;
                  }
                })}
              </Select>
              {myceetyaParentDetails.otherLanguageAtHome === 'Other' && (
                <Stack py={2}>
                  <FormTextField
                    ref={inputRef}
                    label='Other Language'
                    defaultValue={
                      myceetyaParentDetails.otherLanguageAtHome === 'Other'
                        ? ''
                        : myceetyaParentDetails.otherLanguageAtHome
                    }
                    value={inputRef.current}
                    onBlur={(event) => {
                      setParentValues({
                        ...myceetyaParentDetails,
                        otherLanguage: event.target.value,
                        otherLanguageAtHome: 'Other',
                      });
                    }}
                  />
                </Stack>
              )}
            </Stack>

            <Stack gap={1}>
              <FormLabelView
                title='What is the highest year of primary or secondary school you have completed?'
                subtitle='If you have never attended school, mark ‘Year 9 or equivalent or below.'
              />
              <Select
                value={myceetyaParentDetails.highestYearCompleted as string}
                onChange={(event: SelectChangeEvent) => {
                  setParentValues({
                    ...myceetyaParentDetails,
                    highestYearCompleted: event.target.value,
                  });
                }}
              >
                {qualifications?.map((qualification: string) => (
                  <MenuItem value={qualification} key={qualification}>
                    {qualification}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Stack gap={1}>
              <FormLabelView title='What is the level of highest qualification you have completed?' />
              <Select
                value={
                  myceetyaParentDetails.highestQualificationCompleted as string
                }
                onChange={(event: SelectChangeEvent) => {
                  setParentValues({
                    ...myceetyaParentDetails,
                    highestQualificationCompleted: event.target.value,
                  });
                }}
              >
                {educations?.map((education: string) => (
                  <MenuItem value={education} key={education}>
                    {education}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Stack gap={1}>
              <FormLabelView title='What is your occupation group?' />
              <Typography component='small' fontSize={12}>
                Employment groups explanation can be found here:
                <Link href='https://bit.ly/ParentalOccupationGroups2022'>
                  https://bit.ly/ParentalOccupationGroups2022
                </Link>
              </Typography>
              <Typography component='small' fontSize={12}>
                - If you are not currently in paid work but had a job in the
                last 12 months or have retired in the last 12 months, please use
                your last occupation.
              </Typography>
              <Typography component='small' fontSize={12}>
                - If you have not been in paid work in the last 12 months,
                select Group 8.
              </Typography>
              <Select
                value={myceetyaParentDetails?.ocupationGroup as string}
                onChange={(event: SelectChangeEvent) => {
                  setParentValues({
                    ...myceetyaParentDetails,
                    ocupationGroup: event.target.value,
                  });
                }}
                sx={{
                  '& > *': { whiteSpace: 'normal !important' },
                }}
              >
                {occupations?.map((occupation) => (
                  <MenuItem value={occupation.group} key={occupation.group}>
                    {occupation.label}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};
export { ParentForm };
