import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import { useMedicalInfo } from '../useMedicalInformation';
import { format } from 'date-fns';

interface UpdatedViewProps {
  children: ReactNode;
  studentName?: string;
  confirmedDate?: Date;
  createdDate?: Date;
  handleContinue: () => void;
}

const UpdatedView = ({
  children,
  studentName,
  confirmedDate,
  createdDate,
  handleContinue,
}: UpdatedViewProps) => {
  const { handleView } = useMedicalInfo();
  const updatedDate = confirmedDate ?? createdDate;
  const formatDate = (date: Date) => {
    return format(new Date(String(date)), 'dd/MM/yyyy');
  };

  return (
    <>
      <DialogContent>
        {children}
        <Stack gap={3}>
          <Typography fontWeight={400} fontSize={18} paddingTop={3}>
            {studentName}’s medical details have already been updated as part of
            this process on&nbsp;
            {updatedDate ? formatDate(updatedDate) : 'Invalid date'}.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'flex-end' }}>
        <Button
          onClick={() => {
            handleContinue();
            handleView('view');
          }}
          variant='contained'
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export { UpdatedView };
