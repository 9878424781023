import {
  Card,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { FormCheckbox, FormTextField } from '../../../../components/form';
import { useCallback, useMemo, useRef } from 'react';
import { FormLabelView } from '../../components/form-label-view';
import { StudentMyceetyaDetailType } from '../../../../types/myceetya.type';

interface Props {
  languages: Readonly<Array<string>>;
  currentDetail: Array<StudentMyceetyaDetailType>;
  myceetyaStudentDetails: Array<StudentMyceetyaDetailType>;
  setStudentValues: (value: Array<StudentMyceetyaDetailType>) => void;
}

const StudentForm = ({
  languages,
  currentDetail,
  myceetyaStudentDetails,
  setStudentValues,
}: Props) => {
  const languageRef = useRef<HTMLInputElement | null>(null);

  const handleChange = useCallback(
    (id: number, key: string, value: string | boolean | Array<string>) => {
      const items = [...myceetyaStudentDetails] as unknown as any;
      items[id][key] = value;
      setStudentValues(items);
    },
    [myceetyaStudentDetails, currentDetail],
  );

  const handleCheckboxChange = useCallback(
    (id: number, key: string, value: string, override = false) => {
      const items = [...myceetyaStudentDetails] as unknown as any;
      const isAboriginalTorresStraitIslanderOrigin = myceetyaStudentDetails?.[
        id
      ]?.isAboriginalTorresStraitIslanderOrigin
        ? (myceetyaStudentDetails?.[id]
            ?.isAboriginalTorresStraitIslanderOrigin as Array<string>)
        : [];

      if (override) {
        items[id][key] = [value];
        setStudentValues(items);
        return;
      }

      if (isAboriginalTorresStraitIslanderOrigin?.includes?.(value)) {
        items[id][key] = isAboriginalTorresStraitIslanderOrigin.filter(
          (item) => item !== value,
        );
        setStudentValues(items);
      } else {
        const itemsIsAboriginalTorresStraitIslanderOrigin = items?.[id]
          .isAboriginalTorresStraitIslanderOrigin
          ? (items?.[id]
              .isAboriginalTorresStraitIslanderOrigin as Array<string>)
          : [];

        items[id][key] = [
          ...itemsIsAboriginalTorresStraitIslanderOrigin,
          value,
        ];
        setStudentValues(items);
      }
    },
    [myceetyaStudentDetails],
  );

  const suggestions = useMemo(() => languages, [languages]);

  return (
    <Stack>
      <Stack gap={2} mb={2}>
        {myceetyaStudentDetails?.map((pupil, index) => {
          const currentKey = myceetyaStudentDetails?.[index]
            ?.isAboriginalTorresStraitIslanderOrigin as unknown as string[];

          const hasYesAboriginal =
            Array.isArray(currentKey) &&
            currentKey?.includes('Yes, Aboriginal');
          const hasYesTorresStraitIslander =
            Array.isArray(currentKey) &&
            currentKey?.includes('Yes, Torres Strait Islander');

          return (
            <Card key={index}>
              <Stack p={2}>
                <Typography
                  fontSize={18}
                  fontWeight={700}
                >{`${pupil?.firstname} ${pupil?.surname}`}</Typography>
                <Typography component='small'>
                  Student ID: {pupil?.studentId}
                </Typography>
                <Typography component='small'>
                  Address: {pupil?.address}
                </Typography>
                <Stack py={3} gap={2}>
                  <Stack>
                    <FormLabelView
                      title='Is the student of Aboriginal of Torres Strait Islander origin?'
                      subtitle='For persons of both Aboriginal and Torres Strait Islander origin, tick both ‘Yes’ responses.'
                    />
                    <FormControlLabel
                      key={`No-${currentKey?.includes('No')}`}
                      control={
                        <FormCheckbox
                          onClick={() =>
                            handleCheckboxChange(
                              index,
                              'isAboriginalTorresStraitIslanderOrigin',
                              'No',
                              hasYesAboriginal || hasYesTorresStraitIslander,
                            )
                          }
                        />
                      }
                      label='No'
                      checked={currentKey?.includes('No')}
                    />
                    <FormControlLabel
                      key={`Yes-Aboriginal-${currentKey?.includes(
                        'Yes, Aboriginal',
                      )}`}
                      control={
                        <FormCheckbox
                          onClick={() =>
                            handleCheckboxChange(
                              index,
                              'isAboriginalTorresStraitIslanderOrigin',
                              'Yes, Aboriginal',
                              currentKey?.includes('No'),
                            )
                          }
                        />
                      }
                      label='Yes, Aboriginal'
                      checked={currentKey?.includes('Yes, Aboriginal')}
                    />
                    <FormControlLabel
                      key={`Yes-Torres-${currentKey?.includes(
                        'Yes, Torres Strait Islander',
                      )}`}
                      control={
                        <FormCheckbox
                          onClick={() =>
                            handleCheckboxChange(
                              index,
                              'isAboriginalTorresStraitIslanderOrigin',
                              'Yes, Torres Strait Islander',
                              currentKey?.includes('No'),
                            )
                          }
                        />
                      }
                      label='Yes, Torres Strait Islander'
                      checked={currentKey?.includes(
                        'Yes, Torres Strait Islander',
                      )}
                    />
                  </Stack>
                  <Stack>
                    <FormLabelView title='What language does the student speak at home?' />
                    <Select
                      value={
                        myceetyaStudentDetails?.[index]
                          ?.otherLanguageAtHome as string
                      }
                      onChange={(event: SelectChangeEvent) => {
                        if (event.target.value === 'Other') {
                          handleChange(index, 'otherLanguageAtHome', 'Other');
                          handleChange(index, 'otherLanguage', '');
                          return;
                        }
                        handleChange(
                          index,
                          'otherLanguageAtHome',
                          event.target.value,
                        );
                        handleChange(index, 'otherLanguage', '');
                      }}
                    >
                      {suggestions?.map((language: string) => {
                        if (!language) {
                          return;
                        }
                        return <MenuItem value={language}>{language}</MenuItem>;
                      })}
                    </Select>
                    {myceetyaStudentDetails?.[index]?.otherLanguageAtHome ===
                      'Other' && (
                      <Stack py={2}>
                        <FormTextField
                          ref={languageRef}
                          label='Other Language'
                          value={
                            myceetyaStudentDetails?.[index]?.otherLanguage ||
                            languageRef.current
                          }
                          onBlur={(event) => {
                            handleChange(
                              index,
                              'otherLanguage',
                              event.target.value,
                            );
                          }}
                          defaultValue={
                            myceetyaStudentDetails?.[index]?.otherLanguage
                          }
                        />
                      </Stack>
                    )}
                    <Typography
                      component='small'
                      display='block'
                      fontSize={12}
                      pt={0.5}
                    >
                      If more than one language is spoken at home, please
                      indicate the one that is spoken most often.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </Stack>
  );
};
export { StudentForm };
