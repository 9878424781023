import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { useMedicalInfo } from '../useMedicalInformation';
import { ApprovedMedications } from '../cards/approved-medications';
import { Allergies } from '../cards/allergies';
import { Ailments } from '../cards/ailments';
import { CloseInfo } from '../close';

interface SummaryProps {
  children?: ReactNode;
  enableForcedMedicalDetailsUpdates?: boolean;
  handleContinue?: () => void;
  studentIndex?: number;
  studentCount?: number;
}

const SummaryView = ({
  children,
  enableForcedMedicalDetailsUpdates = false,
  handleContinue,
  studentIndex,
  studentCount,
}: SummaryProps) => {
  const {
    handleView,
    handleSave,
    setConfirmClose,
    setinfoToInfoView,
    medications,
    allergies,
    ailments,
    isUpdating,
    isUpdateSuccess,
    isInfoToInfoView,
  } = useMedicalInfo();

  useEffect(() => {
    if (isUpdateSuccess) {
      if (studentIndex !== undefined && studentCount !== undefined) {
        if (studentIndex === studentCount && !isInfoToInfoView) {
          setinfoToInfoView(false);
          handleView('success');
        } else {
          if (handleContinue && studentIndex < studentCount) {
            handleView('view');
            setinfoToInfoView(true);
            handleContinue();
          }
        }
      } else {
        handleView('success');
      }
    }
  }, [isUpdateSuccess]);

  return (
    <>
      {!enableForcedMedicalDetailsUpdates && (
        <CloseInfo
          close={() => {
            setConfirmClose(true);
            handleView('confirm');
          }}
        />
      )}
      <DialogContent>
        <Stack gap={2}>
          {children}
          <Typography fontSize={18} fontWeight={700} textAlign='center'>
            Summary
          </Typography>
          <ApprovedMedications items={medications ?? []} />
          <Allergies items={allergies ?? []} />
          <Ailments items={ailments ?? []} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button
          color='inherit'
          onClick={() => handleView('edit')}
          variant='contained'
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleSave(false);
            if (studentIndex !== undefined && studentCount !== undefined) {
              if (studentIndex === studentCount) {
                setinfoToInfoView(false);
              }
            }
          }}
          variant='contained'
          disabled={isUpdating}
        >
          {isUpdating
            ? 'Saving...'
            : enableForcedMedicalDetailsUpdates
            ? 'Confirm'
            : 'Save'}
        </Button>
      </DialogActions>
    </>
  );
};

export { SummaryView };
