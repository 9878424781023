import { WrapperView } from './WrapperView';

export const StudentOrigin = ({ origin }: { origin: string }) => {
  const value =
    typeof origin === 'string'
      ? origin?.split('Yes,').sort().join('').replace(',', ' and')
      : '';

  return (
    <WrapperView
      label='Is the student of Aboriginal of Torres Strait Islander origin?'
      value={value}
    />
  );
};
