import { ProviderProps, createContext, useContext } from 'react';
import { MedicalInformationType } from '../../../types/medicalinformation.type';

export type ViewType =
  | 'edit'
  | 'view'
  | 'success'
  | 'summary'
  | 'confirm'
  | 'updated';
export type InfoType = 'medications' | 'allergies' | 'ailments';

interface CtxProps {
  data: MedicalInformationType;
  medications: Array<string>;
  allergies: Array<string>;
  ailments: Array<string>;
  view: string;
  confirmClose: boolean;
  hasChanges: boolean;
  isUpdating: boolean;
  isUpdateSuccess: boolean;
  isUpdateError: boolean;
  isInfoToInfoView: boolean;
  handleView: (view: ViewType) => void;
  setMedications: (value: Array<string>) => void;
  setAllergies: (value: Array<string>) => void;
  setAilments: (value: Array<string>) => void;
  setHasChanges: (value: boolean) => void;
  setConfirmClose: (value: boolean) => void;
  handleReset: () => void;
  handleSave: (confirmed: boolean) => void;
  handleClose: () => void;
  other: Record<string, string>;
  setOther: (value: Record<string, string>) => void;
  setinfoToInfoView: (value: boolean) => void;
}

const MedicalInfoContext = createContext<CtxProps>({
  view: 'view',
  data: {
    ailments: [],
    allergies: [],
    medications: [],
    pupilId: '',
  },
  medications: [],
  allergies: [],
  ailments: [],
  confirmClose: false,
  hasChanges: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isInfoToInfoView: false,
  handleView: () => {},
  setMedications: () => {},
  setAllergies: () => {},
  setAilments: () => {},
  setHasChanges: () => {},
  setConfirmClose: () => {},
  handleReset: () => {},
  handleSave: () => {},
  handleClose: () => {},
  other: {
    otherMedicationsText: '',
    otherAilmentsText: '',
    otherAllergiesText: '',
  },
  setOther: () => {},
  setinfoToInfoView: () => {},
});

const MedicalInfoProvider = (props: ProviderProps<CtxProps>) => {
  return <MedicalInfoContext.Provider {...props} />;
};

const useMedicalInfo = () => {
  const context = useContext(MedicalInfoContext);

  if (context === undefined) {
    throw new Error(
      'useMedicalInfo must be used within the MedicalInfoContext Provider',
    );
  }

  return context;
};

export { MedicalInfoProvider, useMedicalInfo };
