import Stack from '@mui/material/Stack';
import {
  ParentMyceetyaDetailType,
  StudentMyceetyaDetailType,
} from '../../../../types/myceetya.type';
import Typography from '@mui/material/Typography';
import { StudentName } from './components/StudentName';
import { StudentDateOfBirth } from './components/StudentDateOfBirth';
import { StudentOrigin } from './components/StudentOrigin';
import { OtherLanguage } from './components/OtherLanguage';
import { WrapperView } from './components/WrapperView';

interface Props {
  details: {
    students: Array<StudentMyceetyaDetailType>;
    parent: ParentMyceetyaDetailType | null;
  };
}

const SummaryView = ({ details }: Props) => {
  const { students, parent } = details;

  return (
    <Stack gap={3} py={3}>
      <Stack>
        <Typography pb={2} fontWeight={700} fontSize={18}>
          Students
        </Typography>
        <Stack>
          {students?.map((student) => {
            return (
              <Stack
                borderRadius={2}
                border='1px solid rgba(0, 0, 0, 0.3)'
                p={2}
                mb={2}
              >
                <StudentName
                  fullName={`${student.firstname} ${student.surname}`}
                  address={student?.address ?? ''}
                  studentId={student?.studentId ?? ''}
                />
                <StudentDateOfBirth birthday={student.dateOfBirth ?? ''} />

                <StudentOrigin
                  origin={
                    String(student?.isAboriginalTorresStraitIslanderOrigin)
                      ?.split('Yes,')
                      .sort()
                      .join('')
                      .replace(',', ' and') ?? ''
                  }
                />

                <OtherLanguage
                  otherLanguage={student?.otherLanguage ?? ''}
                  language={student?.otherLanguageAtHome ?? ''}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack>
        <Typography pb={2} fontWeight={700} fontSize={18}>
          Parent
        </Typography>

        <WrapperView
          label='Name'
          value={`${parent?.firstname} ${parent?.surname}`}
        />
        <WrapperView
          label='Highest Qualification Completed'
          value={parent?.highestQualificationCompleted ?? ''}
        />

        <WrapperView
          label='Highest Year Completed'
          value={parent?.highestYearCompleted ?? ''}
        />

        <WrapperView label='Occupation' value={parent?.ocupationGroup ?? ''} />

        <WrapperView
          label='Other Language at Home'
          value={parent?.ocupationGroup ?? ''}
        />

        <OtherLanguage
          otherLanguage={parent?.otherLanguage ?? ''}
          language={parent?.otherLanguageAtHome ?? ''}
        />
      </Stack>
    </Stack>
  );
};

export { SummaryView };
