import {
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useGetMedicalInformationQuery } from '../../../../../services/medical.service';
import { useMsalIdToken } from '../../../../../hooks/useMsalIdToken';

import { checkArrayEqual } from '../../../../../utils/checkArrayEqual';
import { ContentSelect } from './contents/content-select';
import { useMedicalInfo } from '../../useMedicalInformation';
import { CloseInfo } from '../../close';
import { FormInput } from '../../../../../components/form';

interface EditViewProps {
  children: ReactNode;
  enableForcedMedicalDetailsUpdates?: boolean;
}

const EditView = ({
  children,
  enableForcedMedicalDetailsUpdates = false,
}: EditViewProps) => {
  const token = useMsalIdToken();
  const [openMedications, setOpenMedications] = useState(false);
  const [openAllergies, setOpenAllergies] = useState(false);
  const [openAilments, setOpenAilments] = useState(false);
  const [localToken, setLocalToken] = useState<string | null>(token);
  const [errors, setErrors] = useState<string[]>([]);

  const {
    data,
    medications,
    allergies,
    ailments,
    other,
    setHasChanges,
    handleView,
    handleClose,
    setAilments,
    setAllergies,
    setMedications,
    setConfirmClose,
    setOther,
  } = useMedicalInfo();

  const { data: information } = useGetMedicalInformationQuery(
    { token },
    { skip: !localToken },
  );

  const hasChanges =
    !checkArrayEqual(data?.medications, medications) ||
    !checkArrayEqual(data?.allergies, allergies) ||
    !checkArrayEqual(data?.ailments, ailments);

  useEffect(() => {
    setHasChanges(hasChanges);
  }, [hasChanges]);

  useEffect(() => {
    setLocalToken(token);
  }, [token]);

  const validateForm = () => {
    const newErrors = [];
    if (
      medications.includes('Other Medication') &&
      !other?.otherMedicationsText
    ) {
      newErrors.push('Other Medication description is required.');
    }
    if (allergies.includes('Other Allergy') && !other?.otherAllergiesText) {
      newErrors.push('Other Allergy description is required.');
    }
    if (
      (ailments.includes('Other Mental Health') ||
        ailments.includes('Anaphylaxis - Other') ||
        ailments.includes('Other Ailment')) &&
      !other?.otherAilmentsText
    ) {
      newErrors.push(
        'Other Diagnosed Medical Condition description is required.',
      );
    }
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleContinue = () => {
    if (validateForm()) {
      handleView('summary');
    }
  };

  return (
    <>
      <DialogContent>
        {!enableForcedMedicalDetailsUpdates && (
          <CloseInfo
            close={() => {
              if (hasChanges) {
                setConfirmClose(true);
                handleView('confirm');
                return;
              }

              handleClose();
            }}
          />
        )}
        <Stack py={3} gap={3}>
          {children}
          {!enableForcedMedicalDetailsUpdates && (
            <Typography
              fontWeight={500}
              fontSize={14}
              textAlign='center'
              px={3}
            >
              To add or edit your daughter’s medical information, please select
              from the below lists. If the medication, allergy, or diagnosed
              medical condition isn’t in the list, please select the relevant
              ‘other’ option, and the School’s nursing team will be in touch to
              discuss this with you.
            </Typography>
          )}
          <ContentSelect
            title='Approved Medications'
            isOpen={openMedications}
            toggle={(value) => setOpenMedications(value)}
            menu={
              information &&
              information.medications &&
              [
                ...information.medications.filter(
                  (item: string) => item !== 'Other Medication',
                ),
                'Other Medication',
              ]?.map((medication: string) => (
                <MenuItem value={medication}>{medication}</MenuItem>
              ))
            }
            setItems={(value) => setMedications(value)}
            items={medications}
          >
            {medications?.includes('Other Medication') ? (
              <FormInput
                label={`Other Medication`}
                value={other?.otherMedicationsText}
                onChange={(event) => {
                  setOther({
                    ...other,
                    otherMedicationsText: event?.target?.value,
                  });
                }}
                required
              />
            ) : (
              <div />
            )}
          </ContentSelect>

          <ContentSelect
            title='Allergies'
            isOpen={openAllergies}
            toggle={(value) => setOpenAllergies(value)}
            menu={
              information &&
              information.allergies &&
              [
                ...information.allergies.filter(
                  (item: string) => item !== 'Other Allergy',
                ),
                'Other Allergy',
              ]?.map((allergy: string) => (
                <MenuItem value={allergy}>{allergy}</MenuItem>
              ))
            }
            setItems={setAllergies}
            items={allergies}
          >
            {allergies?.includes('Other Allergy') ? (
              <FormInput
                label={`Other Allergy`}
                value={other?.otherAllergiesText}
                onChange={(event) => {
                  setOther({
                    ...other,
                    otherAllergiesText: event?.target?.value,
                  });
                }}
                required
              />
            ) : (
              <div />
            )}
          </ContentSelect>

          <ContentSelect
            title='Diagnosed Medical Conditions'
            isOpen={openAilments}
            toggle={(value) => setOpenAilments(value)}
            menu={
              information &&
              information.ailments &&
              [
                ...information.ailments.filter(
                  (item: string) =>
                    item !== 'Other Mental Health' &&
                    item !== 'Other Ailment' &&
                    item !== 'Anaphylaxis - Other',
                ),
                'Anaphylaxis - Other',
                'Other Mental Health',
                'Other Ailment',
              ]?.map((ailment: string) => (
                <MenuItem value={ailment}>{ailment}</MenuItem>
              ))
            }
            setItems={setAilments}
            items={ailments}
          >
            {ailments?.includes('Other Mental Health') ||
            ailments?.includes('Anaphylaxis - Other') ||
            ailments?.includes('Other Ailment') ? (
              <FormInput
                label={`Other Diagnosed Medical Condition`}
                value={other?.otherAilmentsText}
                onChange={(event) => {
                  setOther({
                    ...other,
                    otherAilmentsText: event?.target?.value,
                  });
                }}
                required
              />
            ) : (
              <div />
            )}
          </ContentSelect>
          {errors.length > 0 && (
            <Stack>
              {errors.map((error, index) => (
                <Typography key={index} color='error'>
                  {error}
                </Typography>
              ))}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button
          color='inherit'
          onClick={() => {
            handleView(hasChanges ? 'confirm' : 'view');
          }}
          variant='contained'
        >
          Back
        </Button>
        <Button
          onClick={handleContinue}
          variant='contained'
          disabled={!hasChanges}
        >
          Continue
        </Button>
      </DialogActions>
    </>
  );
};

export { EditView };
