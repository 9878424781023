import { Dialog, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { InfoView } from './view/info';
import { StudentAvatar } from '../../../components/student-list/student-avatar';
import { ConfirmView } from './view/confirm';
import { EditView } from './view/edit';
import { SuccessView } from './view/success';
import { SummaryView } from './view/summary';
import { useUpdateMedicalInformationMutation } from '../../../services/medical.service';
import { useGetMedicalInformation } from '../hooks/useGetMedicalInformation';
import { useMsalIdToken } from '../../../hooks/useMsalIdToken';
import { MedicalInfoProvider, ViewType } from './useMedicalInformation';
import { Box } from '@mui/material';
import { PupilType } from '../../../types/students.type';
import { UpdatedView } from './view/updated';

interface ForcedMedicalUpdatesProps {
  open: boolean;
  handleNextStudent: () => void;
  studentInfo: PupilType;
  studentCount: number;
  studentIndex: number;
  handleClose: () => void;
}

export const ForcedMedicalUpdates = ({
  open,
  handleNextStudent,
  studentInfo,
  studentCount,
  studentIndex,
  handleClose,
}: ForcedMedicalUpdatesProps) => {
  const token = useMsalIdToken();
  const currentStudent = studentInfo;

  const { data } = useGetMedicalInformation(currentStudent?.pupilId);

  const [
    updateMedicalInformation,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateMedicalInformationMutation();

  const [viewState, setViewState] = useState<ViewType>('view');
  const [medications, setMedications] = useState<Array<string>>([]);
  const [allergies, setAllergies] = useState<Array<string>>([]);
  const [ailments, setAilments] = useState<Array<string>>([]);
  const [other, setOther] = useState<Record<string, string>>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [isInfoToInfoView, setinfoToInfoView] = useState(false);

  const handleView = (view: ViewType) => {
    setViewState(view);
  };

  const handleSave = (confirmed = false, isForcedMedicalUpdate = true) => {
    const payload = {
      pupilId: currentStudent?.pupilId,
      medications,
      allergies,
      ailments,
      ...other,
      confirmed,
      isForcedMedicalUpdate,
    };

    void updateMedicalInformation({
      token,
      payload,
    });
  };

  const StudentInfo = () => {
    return (
      <Stack
        flexDirection='row'
        alignItems='center'
        gap={2}
        position='relative'
      >
        <StudentAvatar
          pupilId={currentStudent?.pupilId ?? ''}
          fullName={`${currentStudent?.forename} ${currentStudent?.surname}`}
          size={50}
          acronym={currentStudent?.avatarAcronym ?? ''}
        />
        <Stack>
          <Typography fontWeight={700} fontSize={20} color='primary'>
            {currentStudent?.forename}
          </Typography>
          <Typography fontWeight={500} fontSize={14}>
            {currentStudent?.yearGroup}
          </Typography>
        </Stack>
        {studentCount > 1 && (
          <Box position='absolute' top={0} right={0}>
            <Typography fontWeight={500} fontSize={14}>
              {studentIndex} of {studentCount} Students
            </Typography>
          </Box>
        )}
      </Stack>
    );
  };

  const message = (
    <>
      Please review the medical information the School holds for your daughter.
      <br />
      You are asked to <b>Confirm</b> if this is correct, or <b>Edit</b> to make
      updates.
    </>
  );

  const HeaderInfo = () => {
    return (
      <Stack
        flexDirection='column'
        alignItems='left'
        gap={1}
        sx={{
          minWidth: { xs: '280px', md: '1000px' },
          padding: 2,
        }}
      >
        <Typography fontWeight={700} fontSize={24}>
          Medical Details Update
        </Typography>
        <Typography fontWeight={400} fontSize={16}>
          {message}
        </Typography>
      </Stack>
    );
  };

  const MainView = () => {
    switch (viewState) {
      case 'view':
        return (
          <>
            <HeaderInfo />
            <Box paddingX={2} paddingBottom={2}>
              <Box
                sx={{
                  border: '1px solid',
                  minWidth: { xs: '280px', md: '500px' },
                }}
              >
                <InfoView
                  enableForcedMedicalDetailsUpdates={true}
                  handleContinue={handleNextStudent}
                  studentIndex={studentIndex}
                  studentCount={studentCount}
                >
                  <StudentInfo />
                </InfoView>
              </Box>
            </Box>
          </>
        );
      case 'edit':
        return (
          <>
            <HeaderInfo />
            <Box paddingX={2} paddingBottom={2}>
              <Box
                sx={{
                  border: '1px solid',
                  minWidth: { xs: '280px', md: '500px' },
                }}
              >
                <EditView enableForcedMedicalDetailsUpdates={true}>
                  <StudentInfo />
                </EditView>
              </Box>
            </Box>
          </>
        );
      case 'summary':
        return (
          <>
            <HeaderInfo />
            <Box paddingX={2} paddingBottom={2}>
              <Box
                sx={{
                  border: '1px solid',
                  minWidth: { xs: '280px', md: '500px' },
                }}
              >
                <SummaryView
                  enableForcedMedicalDetailsUpdates={true}
                  handleContinue={handleNextStudent}
                  studentIndex={studentIndex}
                  studentCount={studentCount}
                >
                  <StudentInfo />
                </SummaryView>
              </Box>
            </Box>
          </>
        );
      case 'confirm':
        return <ConfirmView enableForcedMedicalDetailsUpdates={true} />;
      case 'updated':
        return (
          <>
            <HeaderInfo />
            <Box paddingX={2} paddingBottom={2}>
              <Box
                sx={{
                  border: '1px solid',
                  minWidth: { xs: '280px', md: '500px' },
                }}
              >
                <UpdatedView
                  studentName={currentStudent?.forename}
                  confirmedDate={data?.confirmedOn}
                  createdDate={data?.created}
                  handleContinue={handleNextStudent}
                >
                  <StudentInfo />
                </UpdatedView>
              </Box>
            </Box>
          </>
        );
      default:
        if (studentCount === studentIndex) {
          return (
            <SuccessView
              handleClose={handleClose}
              enableForcedMedicalDetailsUpdates={true}
            />
          );
        }
    }
  };

  const handleReset = () => {
    if (data) {
      setMedications([...(data.medications || [])]);
      setAllergies([...(data?.allergies || [])]);
      setAilments([...(data?.ailments || [])]);
    }
  };

  const memoizedValues = useMemo(
    () => ({
      view: 'view',
      data,
      medications,
      allergies,
      ailments,
      hasChanges,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
      confirmClose,
      other,
      isInfoToInfoView,
      setMedications,
      setAllergies,
      setAilments,
      handleView,
      handleReset,
      handleSave,
      handleClose,
      setHasChanges,
      setConfirmClose,
      setOther,
      setinfoToInfoView,
    }),
    [
      data,
      medications,
      allergies,
      ailments,
      other,
      confirmClose,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
    ],
  );

  useEffect(() => {
    if (data) {
      setMedications([...(data?.medications || [])]);
      setAllergies([...(data?.allergies || [])]);
      setAilments([...(data?.ailments || [])]);
      // Check if confirmed / created date year and today's year is same to get updated view
      if (
        new Date(data?.confirmedOn).getFullYear() ===
          new Date().getFullYear() ||
        new Date(data?.created).getFullYear() === new Date().getFullYear()
      ) {
        handleView('updated');
      }
    }
  }, [data]);

  return (
    <MedicalInfoProvider value={memoizedValues}>
      <Dialog
        disableEscapeKeyDown={true}
        open={open}
        onClose={() => {}}
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'auto',
            overflowX: 'hidden',
          },
        }}
      >
        {MainView()}
      </Dialog>
    </MedicalInfoProvider>
  );
};
